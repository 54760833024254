/**
 * Copyright 2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { PlanNotificationTriggerType } from "~/generated/graphql";

export const optionsCondition = [
  {
    value: PlanNotificationTriggerType.PlansCreated,
    label: "Plan is created",
  },
  {
    value: PlanNotificationTriggerType.PlansUpdated,
    label: "Plan is updated",
  },
  {
    value: PlanNotificationTriggerType.PlansDeleted,
    label: "Plan is deleted",
  },
  {
    value: PlanNotificationTriggerType.EventsStarted,
    label: "Plan starts",
  },
  {
    value: PlanNotificationTriggerType.EventsSucceeded,
    label: "Plan succeeds",
  },
  {
    value: PlanNotificationTriggerType.EventsFailed,
    label: "Plan fails",
  },
  {
    value: PlanNotificationTriggerType.EventsIncoming,
    label: "Plan is upcoming",
  },
  {
    value: PlanNotificationTriggerType.EventsPartiallySucceeded,
    label: "Plan is partially succeeded",
  },
];
