/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */
import { useEffect, useMemo } from "react";
import dayjs from "dayjs";
import { useFieldArray, useFormContext } from "react-hook-form";
import { When } from "react-if";
import { Button, FlexContainer, Label, Spacer, theme } from "@nordcloud/gnui";
import { FormGroup } from "~/components";
import { dateFormat } from "~/constants";
import { isNotEmpty } from "~/tools";
import { ScheduleTimezone } from "~/views/plans/components";
import {
  FormField,
  DateItem,
} from "~/views/plans/PlanCreate/components/PlanCreateWizard/types";
import { ScheduleState } from "~/views/plans/PlanDetails/types";
import { ScheduleType } from "~/views/plans/types";
import { convertDateItemToDateString } from "~/views/plans/utils";
import { ExecutionDateItemDetails } from "./ExecutionDateItemDetails";

type Props = {
  state: ScheduleState;
};

export function SpecifiedDatesScheduleDetails({ state }: Props) {
  const {
    control,
    watch,
    formState: { errors },
  } = useFormContext();

  const initialDates = state?.scheduleExecutionDates;

  const { fields, append, remove, replace } = useFieldArray({
    control,
    name: FormField.SCHEDULE_EXECUTION_DATES,
  });

  const initialFields = useMemo(() => {
    return (
      initialDates?.map((date: DateItem) => {
        return {
          [FormField.EXECUTION_DATE]: convertDateItemToDateString(
            date,
            dateFormat.shortDate
          ),
          [FormField.EXECUTION_TIME]: convertDateItemToDateString(
            date,
            dateFormat.hourMinute
          ),
        };
      }) ?? []
    );
  }, [initialDates]);

  const onAddNewExecutionDate = () => {
    const lastSchedule = watch(FormField.SCHEDULE_EXECUTION_DATES)[
      fields.length - 1
    ];

    const isPastDate = dayjs(
      `${lastSchedule?.executionDate} ${lastSchedule?.executionTime}`
    ).isBefore(dayjs().tz(state.scheduleTimezone).format(dateFormat.fullDate));

    const executionDate = isPastDate
      ? dayjs().tz(state.scheduleTimezone).format(dateFormat.shortDate)
      : lastSchedule?.executionDate;
    const executionTime = isPastDate
      ? dayjs().tz(state.scheduleTimezone).format(dateFormat.hourMinute)
      : lastSchedule?.executionTime;
    const newExecutionDate = lastSchedule
      ? dayjs(executionDate).format(dateFormat.shortDate)
      : dayjs().tz(state.scheduleTimezone).format(dateFormat.shortDate);
    const newExecutionTime = lastSchedule
      ? dayjs(`${executionDate} ${executionTime}`)
          .add(3, "hour")
          .format(dateFormat.hourMinute)
      : dayjs().tz(state.scheduleTimezone).format(dateFormat.hourMinute);

    append({
      [FormField.EXECUTION_DATE]: newExecutionDate,
      [FormField.EXECUTION_TIME]: newExecutionTime,
    });
  };

  useEffect(() => {
    if (state.scheduleType === ScheduleType.specifiedDates) {
      replace(initialFields);
    }
  }, [initialFields, replace, state.scheduleType]);

  const dates = watch(FormField.SCHEDULE_EXECUTION_DATES);
  return (
    <>
      <ScheduleTimezone />
      <When condition={isNotEmpty(fields)}>
        <FlexContainer>
          <Label name="Select Date" css={{ width: "12rem" }} required />
          <Label name="Select Time" required />
        </FlexContainer>
      </When>
      <FormGroup error={errors[FormField.SCHEDULE_EXECUTION_DATES]}>
        <>
          {fields.map((field, index) => {
            const findInitialDates = dates[index];

            const findInitialDatesFormatted = dayjs(
              `${findInitialDates.executionDate} ${findInitialDates.executionTime}`
            ).utc();

            const isPastDate =
              initialDates.some((initialDate) =>
                dayjs(
                  `${initialDate?.executionDate} ${initialDate?.executionTime}`
                )
                  .utc()
                  .isSame(findInitialDatesFormatted)
              ) &&
              findInitialDatesFormatted.isBefore(
                dayjs().tz(state.scheduleTimezone).format(dateFormat.fullDate)
              );

            return (
              <ExecutionDateItemDetails
                key={field.id}
                index={index}
                remove={() => remove(index)}
                disabled={isPastDate}
              />
            );
          })}
          <Spacer height={theme.spacing.spacing04} />
        </>
      </FormGroup>
      <Button
        severity="low"
        icon="plus"
        type="button"
        css={{ marginTop: theme.spacing.spacing08 }}
        onClick={onAddNewExecutionDate}
      >
        Add Date
      </Button>
    </>
  );
}
