/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useEffect, useMemo, useState } from "react";
import { Col, Row } from "react-awesome-styled-grid";
import { Case, Switch } from "react-if";
import { v4 } from "uuid";
import { Box, FlexContainer, Text } from "@nordcloud/gnui";
import { PlanAction } from "~/generated/graphql";
import { NoData } from "~/components";
import { Detail } from "~/components/Details/Detail";
import { ToggleItemBox } from "~/components/ToggleItemBox/ToggleItemBox";
import { useDisclosure } from "~/hooks";
import { isEmpty, isNotEmpty } from "~/tools";
import { PlanData } from "~/views/plans/PlanCreate/components/PlanCreateWizard/constants";
import { PlanWizardCtxProvider } from "~/views/plans/PlanCreate/components/PlanCreateWizard/PlanProvider";
import { PlanSettingsForm } from "~/views/plans/PlanCreate/components/PlanCreateWizard/PlanSettingsForms/PlanSettingsForm";
import { ScheduleType } from "~/views/plans/types";
import { DetailPlanActionName } from "../../components/presets";
import { usePlan } from "../../PlanProvider";
import { ActionBox } from "./components/ActionBox/ActionBox";
import { DetailsTabBar } from "./DetailsTabBar";

export function DetailsTab() {
  const { open, close, isOpen } = useDisclosure();
  const { plan, planAction, setPlanAction } = usePlan();

  const actions = useMemo(() => plan?.planActions ?? [], [plan]);

  const addListId = actions.map((item) => {
    const {
      __typename: IgnoredTypename,
      resourceGroups,
      windowDuration,
      action,
      inputParameters,
      notificationGroups,
      name,
      ...rest
    } = item;

    return {
      windowDuration: windowDuration.toString(),
      inputParameters: inputParameters ?? [],
      ...rest,
      ...action,
      name: name,
      actionName: item.action.name,
      listId: v4(),
      id: item.id ?? "",
      resourceGroups,
      resourceGroupIds: resourceGroups?.map((group) => group.id),
      notificationGroups: notificationGroups?.map((group) => ({
        id: group.id ?? "",
        notificationGroup: {
          name: group.notificationGroup?.name,
          id: group.notificationGroup?.name,
        },
      })),
    };
  });

  const [planData, setPlanData] = useState<PlanData>({
    details: undefined,
    schedule_plan: undefined,
    schedule_type: ScheduleType.recurring,
    general_notifications: undefined,
    plan_status: undefined,
    plan_settings: { planActions: addListId },
  });
  const [orderOfSelectedAction, setOrderOfSelectedAction] = useState(0);

  useEffect(() => {
    setPlanAction?.(actions[orderOfSelectedAction]);
  }, [actions, orderOfSelectedAction, setPlanAction]);

  return (
    <PlanWizardCtxProvider
      value={{
        planData,
        setPlanData,
        orderOfSelectedAction,
        setOrderOfSelectedAction,
      }}
    >
      <Box innerSpacing="spacing00">
        <DetailsTabBar
          openEditMode={open}
          closeEditMode={close}
          isEditMode={isOpen}
        />
        <Switch>
          <Case condition={isOpen}>
            <PlanSettingsForm />
          </Case>
          <Case condition={!isOpen && isNotEmpty(actions)}>
            <Row>
              <Col xs={4} sm={8} md={2} lg={3}>
                <FlexContainer direction="column" justifyContent="flex-start">
                  <Text width="100%" align="left">
                    Actions
                  </Text>
                  {actions?.map((item: PlanAction, index: number) => (
                    <ToggleItemBox
                      key={item.id}
                      item={{
                        id: item.id,
                        color: item.action?.color,
                        name: (
                          <Detail
                            detail={DetailPlanActionName(item)}
                            customCss={{ marginBottom: 0 }}
                            editButtonSize="sm"
                          />
                        ),
                        onMouseDown: () => {
                          setOrderOfSelectedAction(index);
                          setPlanAction?.(item);
                        },
                      }}
                      active={item.id === planAction?.id}
                    />
                  ))}
                </FlexContainer>
              </Col>
              <Col xs={4} sm={8} md={6} lg={9}>
                <Box boxStyle="grey" innerSpacing="spacing04">
                  <FlexContainer alignItems="flex-start">
                    <ActionBox />
                  </FlexContainer>
                </Box>
              </Col>
            </Row>
          </Case>
          <Case condition={!isOpen && isEmpty(actions)}>
            <NoData hasIcon message="There are no Actions" />
          </Case>
        </Switch>
      </Box>
    </PlanWizardCtxProvider>
  );
}
