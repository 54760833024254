/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Dispatch, useEffect } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { Col, Row } from "react-awesome-styled-grid";
import {
  FormProvider,
  useFieldArray,
  useForm,
  SubmitHandler,
} from "react-hook-form";
import { v4 } from "uuid";
import { z } from "zod";
import { Button, Text } from "@nordcloud/gnui";
import {
  PlanNotificationGroup,
  PlanNotificationTriggerType,
} from "~/generated/graphql";
import { stringRequired } from "~/components/Forms";
import { areObjectsEqual } from "~/utils/json";
import { FormData } from "~/views/plans/PlanCreate/components/PlanCreateWizard/formConfig";
import {
  FormField,
  TimeUnits,
} from "~/views/plans/PlanCreate/components/PlanCreateWizard/types";
import { NotificationItemGeneral } from "../../NotificationItemGeneral/NotificationItemGeneral";
import { usePlanWizard } from "../PlanProvider";

type State = {
  isOpen: boolean;
  hasUnsavedChanges: boolean;
  hasChanges: boolean;
};

export const NotificationGroupGeneralSchema = z
  .object({
    [FormField.ID]: z.string(),
    [FormField.TRIGGER_EVENT]: stringRequired("Condition"),
    [FormField.NOTIFICATION_GROUPS_IDS]: z.string().array(),
    [FormField.IN_ADVANCE]: z.string().optional(),
    [FormField.UNIT]: z.string().optional(),
  })
  .refine(
    (data) => {
      if (data.triggerEvent === PlanNotificationTriggerType.EventsIncoming) {
        return Number(data.inAdvance) > 0;
      } else {
        return true;
      }
    },
    {
      message: "In Advance has to been greater than 0",
      path: [FormField.IN_ADVANCE],
    }
  )
  .array();

export const schema = z.object({
  [FormField.NOTIFICATION_GROUPS_GENERAL]:
    NotificationGroupGeneralSchema.optional(),
});

type Props = {
  nextStep?: () => void;
  onSubmit: (formData: FormData) => void;
  notificationGroups?: PlanNotificationGroup[];
  state?: State;
  updateState?: Dispatch<Partial<State>>;
};

export function PlanGeneralNotificationForm({
  nextStep,
  onSubmit,
  notificationGroups,
  state,
  updateState,
}: Props) {
  const { planData } = usePlanWizard();

  const defaultValues =
    planData?.general_notifications?.notificationGroupsGeneral?.map((item) => {
      return {
        [FormField.ID]: item.id,
        [FormField.TRIGGER_EVENT]: item?.triggerEvent,
        [FormField.NOTIFICATION_GROUPS_IDS]: item.notificationGroupIds,
        [FormField.IN_ADVANCE]: item.inAdvance,
        [FormField.UNIT]: item.unit,
      };
    });
  const formMethods = useForm<FormData>({
    resolver: zodResolver(schema),
    defaultValues: {
      [FormField.NOTIFICATION_GROUPS_GENERAL]: defaultValues ?? [],
    },
  });

  const { control, handleSubmit, watch } = formMethods;

  const currentState = watch(FormField.NOTIFICATION_GROUPS_GENERAL)?.map(
    (group) => {
      const { id: IgnoredId, ...groupWithoutId } = group;
      return groupWithoutId;
    }
  );

  const previousState = notificationGroups?.map((item) => {
    return {
      [FormField.TRIGGER_EVENT]: item?.triggerEvent,
      [FormField.NOTIFICATION_GROUPS_IDS]: [item.notificationGroup.id],
      [FormField.IN_ADVANCE]: item.inAdvance?.toString() ?? "0",
      [FormField.UNIT]: TimeUnits.minutes,
    };
  });

  const isStateUpdated = areObjectsEqual(currentState, previousState);

  useEffect(() => {
    updateState?.({ hasUnsavedChanges: !isStateUpdated });
  }, [isStateUpdated, updateState]);

  const submit: SubmitHandler<FormData> = (formData) => {
    onSubmit(formData);

    nextStep?.();
  };

  const { fields, append, remove, replace } = useFieldArray({
    control,
    name: FormField.NOTIFICATION_GROUPS_GENERAL,
  });

  useEffect(() => {
    if (state?.hasChanges) {
      replace(previousState ?? []);
      updateState?.({ hasChanges: false });
    }
  }, [replace, previousState, state?.hasChanges, updateState]);

  return (
    <Row justify="center">
      <Col sm={6} md={6} lg={8}>
        <Row>
          <Col>
            <Text size="md" tag="h5">
              General Plan Notifications
            </Text>
            <Text size="sm">Set general plan notifications.</Text>
            <FormProvider {...formMethods}>
              <form
                id="planGeneralNotificationForm"
                data-testid="plan-general-notification"
                onSubmit={handleSubmit(submit)}
              >
                {fields.map((field, index) => {
                  return (
                    <NotificationItemGeneral
                      key={field.id}
                      index={index}
                      remove={() => remove(index)}
                    />
                  );
                })}
                <Button
                  severity="low"
                  icon="plus"
                  type="button"
                  data-testid="plan-general-notification-add"
                  onClick={() =>
                    append({
                      [FormField.ID]: v4(),
                      [FormField.TRIGGER_EVENT]: undefined,
                      [FormField.NOTIFICATION_GROUPS_IDS]: undefined,
                      [FormField.IN_ADVANCE]: "0",
                      [FormField.UNIT]: TimeUnits.minutes,
                    })
                  }
                >
                  Add Notification
                </Button>
              </form>
            </FormProvider>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
