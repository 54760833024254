/**
 * Copyright 2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { v4 } from "uuid";
import { Text } from "@nordcloud/gnui";
import { NextDateTooltip } from "../components/NextDateTooltip";

type Props = {
  cronExpression?: string;
  nextExecutionDates: string[];
};

export function NextCronDatesTooltip({
  nextExecutionDates,
  cronExpression,
}: Props) {
  return (
    <NextDateTooltip
      tooltip={nextExecutionDates.map((date) => (
        <Text key={v4()} size="sm" mb={0} color="text01">
          Next: {date}
        </Text>
      ))}
      description={`Cron: ${cronExpression}`}
    />
  );
}
