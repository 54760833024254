/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Col, Row } from "react-awesome-styled-grid";
import { Else, If, Then, When } from "react-if";
import { Box, BrickLoader, theme } from "@nordcloud/gnui";
import {
  EventAction,
  EventActionAttempt,
  EventActionAttemptResourceStatesFragment,
  EventActionResourceState,
  EventActionResourceStatesFragment,
  EventActionScope,
} from "~/generated/graphql";
import { LoaderWrap } from "~/components";
import { EventResourceGroupSection } from "~/components/resourceGroup/EventResourceGroupSection";
import { isNotEmpty } from "~/tools";
import { ResourceGroupsStates } from "~/views/events/EventsDetails/types";
import { ActionSettingsSection } from "./components/ActionSettingsSection";
import { EventResourceHeader } from "./components/EventResourceHeader";

type Props = {
  eventAction: EventAction;
  resourceStates?:
    | EventActionResourceStatesFragment["resourceStates"]
    | EventActionAttemptResourceStatesFragment["resourceStates"];
  planId?: string;
  attempt?: EventActionAttempt;
};

export function EventActionBox({
  eventAction,
  resourceStates,
  planId,
  attempt,
}: Props) {
  const states = resourceStates?.reduce(
    (result: ResourceGroupsStates, state: EventActionResourceState) => {
      const rgId = state.resourceGroupId;
      return {
        ...result,
        [rgId]: result[rgId] ? [...result[rgId], state] : [state],
      };
    },
    {}
  );

  return (
    <Row>
      <If condition={eventAction.scope === EventActionScope.Resource}>
        <Then>
          <Col
            xs={4}
            sm={4}
            md={4}
            lg={8}
            css={{ paddingRight: theme.spacing.spacing01 }}
          >
            <Box boxStyle="grey" innerSpacing="spacing00">
              <LoaderWrap
                loading={states === undefined}
                Component={() => <BrickLoader height="28rem" />}
              >
                <When condition={isNotEmpty(resourceStates ?? [])}>
                  <EventResourceHeader
                    eventAction={eventAction}
                    attempt={attempt}
                  />
                </When>
                <EventResourceGroupSection
                  states={states}
                  eventAction={eventAction}
                />
              </LoaderWrap>
            </Box>
          </Col>
          <Col xs={4} sm={4} md={4} lg={4}>
            <Box innerSpacing="spacing00" css={{ height: "100%" }}>
              <ActionSettingsSection
                eventAction={eventAction}
                {...{ planId }}
              />
            </Box>
          </Col>
        </Then>
        <Else>
          <Col xs={8} sm={8} md={12} lg={12}>
            <Box innerSpacing="spacing00" css={{ height: "100%" }}>
              <ActionSettingsSection
                eventAction={eventAction}
                {...{ planId }}
              />
            </Box>
          </Col>
        </Else>
      </If>
    </Row>
  );
}
