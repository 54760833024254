/**
 * Copyright 2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import {
  Maybe,
  PlanNotificationGroup,
  PlanNotificationTriggerType,
} from "~/generated/graphql";
import {
  NotificationGroupItemGeneral,
  TimeUnits,
} from "~/views/plans/PlanCreate/components/PlanCreateWizard/types";
import { convertToMinutes } from "~/views/plans/utils";

export type ExistingNotification = {
  id?: string;
  notificationGroupId: string;
  triggerEvent: PlanNotificationTriggerType;
  inAdvance: number;
};

export function getFormDataIds(
  notificationGroupsGeneral?: NotificationGroupItemGeneral[]
): string[] {
  return (
    notificationGroupsGeneral
      ?.map(({ id }) => id)
      .filter((id): id is string => id !== undefined) ?? []
  );
}

export function getDeleteNotificationGroupsFromList(
  notificationGroups?: PlanNotificationGroup[],
  formDataIds?: string[]
): string[] {
  return (
    notificationGroups
      ?.filter((notification) => !formDataIds?.includes(notification.id))
      .map(({ id }) => id) ?? []
  );
}

export function mapNotifications(notifications: PlanNotificationGroup[]) {
  return notifications.map((notification) => ({
    notificationGroupId: notification.notificationGroup.id,
    triggerEvent: notification.triggerEvent,
    inAdvance: notification.inAdvance,
  }));
}

export function mapNotificationGroups(
  notificationsGroupsToUpdate: ExistingNotification[]
) {
  return notificationsGroupsToUpdate.map((notification) => ({
    notificationGroupId: notification.notificationGroupId,
    triggerEvent: notification.triggerEvent,
    inAdvance: notification.inAdvance,
  }));
}

export function getDeleteNotificationGroupsFromNestedGroup(
  generalNotifications?: NotificationGroupItemGeneral[],
  planNotificationGroups?: PlanNotificationGroup[]
) {
  return (
    generalNotifications
      ?.reduce<string[]>((acc, generalNotification) => {
        const matchingIds = planNotificationGroups?.reduce<string[]>(
          (accInner, planNotificationGroup) => {
            if (
              generalNotification.id === planNotificationGroup.id &&
              !generalNotification.notificationGroupIds?.includes(
                planNotificationGroup.notificationGroup.id
              )
            ) {
              return [...accInner, planNotificationGroup.id];
            }
            return accInner;
          },
          []
        );
        return [...acc, ...(matchingIds || [])];
      }, [])
      ?.filter((item) => item !== undefined) ?? [].flat()
  );
}

export function mapNotificationGroupsToUpdate(
  generalNotifications?: NotificationGroupItemGeneral[],
  planNotificationGroups?: Maybe<PlanNotificationGroup[]>
) {
  return (
    generalNotifications
      ?.map((generalNotification) => {
        return (
          generalNotification.notificationGroupIds
            ?.filter((item) => item !== undefined)
            ?.map((groupId) => {
              const findGroup = planNotificationGroups?.find(
                (item) => item.id === generalNotification.id
              )?.notificationGroup.id;

              const checkIsExist = findGroup === groupId;

              return {
                id: checkIsExist ? generalNotification.id : undefined,
                notificationGroupId: groupId,
                triggerEvent:
                  generalNotification.triggerEvent ??
                  PlanNotificationTriggerType.EventsStarted,
                inAdvance:
                  generalNotification.triggerEvent ===
                  PlanNotificationTriggerType.EventsIncoming
                    ? Number(
                        convertToMinutes(
                          generalNotification.inAdvance?.toString() ?? "",
                          generalNotification.unit ?? TimeUnits.minutes
                        )
                      )
                    : 0,
              };
            }) ?? []
        );
      })
      .flat() ?? []
  );
}
