/**
 * Copyright 2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Button, FlexContainer, Text, theme } from "@nordcloud/gnui";
import { LoaderWrap } from "~/components";
import { DetailWrapper, ValueWrapper } from "~/components/Details/styles";

type Props = {
  loading: boolean;
  nextExecutionDate: (() => string) | undefined;
  handleEdit: () => void;
};
export function NextExecutionComponent({
  loading,
  nextExecutionDate,
  handleEdit,
}: Props) {
  return (
    <>
      <DetailWrapper data-testid="Next execution">
        <FlexContainer alignItems="flex-start" direction="column">
          <Text
            tag="div"
            size="sm"
            color={theme.color.text.text02}
            css={{ textDecoration: "none" }}
          >
            Next execution
          </Text>
          <ValueWrapper editable isEmpty={false}>
            <LoaderWrap loading={loading} inContent>
              <div data-testid="Plan Cron">
                {nextExecutionDate?.() ?? "On Demand"}
              </div>
            </LoaderWrap>
          </ValueWrapper>
        </FlexContainer>
        <Button
          aria-label="edit button"
          icon="edit"
          size="md"
          severity="low"
          ml={theme.spacing.spacing02}
          onClick={handleEdit}
          css={{ visibility: "hidden" }}
        />
      </DetailWrapper>
    </>
  );
}

export default NextExecutionComponent;
