/**
 * Copyright 2022-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Box, FlexContainer } from "@nordcloud/gnui";
import { ResourcesSortFields, SortOrder } from "~/generated/graphql";
import { SortSelector } from "~/components/SortSelector";
import { useQueryState } from "~/hooks";
import { QuerySort } from "../types";
import { Actions } from "./Actions";

const sortOptions = [
  {
    value: ResourcesSortFields.Name,
    label: "Resource Name",
  },
  {
    value: ResourcesSortFields.CreatedAt,
    label: "Date Added",
  },
];

type Props = {
  resourcesDetails: {
    id: string;
    name: string;
    scanned: boolean;
  }[];
};

export function ResourceListHeader({ resourcesDetails }: Props) {
  const { queryState, updateQueryState } = useQueryState<QuerySort>();

  const handleSortDirection = (order: SortOrder) => {
    updateQueryState({
      ...queryState,
      order,
    });
  };

  const handleSortField = (field: ResourcesSortFields) => {
    updateQueryState({
      ...queryState,
      field,
    });
  };

  return (
    <Box boxStyle="lightGrey" spacing="spacing04">
      <FlexContainer justifyContent="space-between">
        <SortSelector<ResourcesSortFields>
          options={sortOptions}
          value={queryState.field ?? ResourcesSortFields.Name}
          direction={queryState.order ?? SortOrder.Asc}
          onSelect={handleSortField}
          onSortChange={handleSortDirection}
        />
        <Actions size="large" {...{ resourcesDetails }} />
      </FlexContainer>
    </Box>
  );
}
