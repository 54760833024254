/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useReducer, useState } from "react";
import { Col, Row } from "react-awesome-styled-grid";
import { Case, Switch, When } from "react-if";
import { Box, Text, theme } from "@nordcloud/gnui";
import { PlanNotificationTriggerType } from "~/generated/graphql";
import { NoData } from "~/components";

import { showSuccess } from "~/services/toast";
import { generateActionSuccessText, isEmpty, isNotEmpty } from "~/tools";
import { areObjectsEqual } from "~/utils/json";
import { useUpdatePlan } from "~/views/plans/hooks/useUpdatePlan/useUpdatePlan";
import {
  PlanData,
  PlanField,
} from "~/views/plans/PlanCreate/components/PlanCreateWizard/constants";
import { FormData } from "~/views/plans/PlanCreate/components/PlanCreateWizard/formConfig";
import { PlanGeneralNotificationForm } from "~/views/plans/PlanCreate/components/PlanCreateWizard/PlanGeneralNotificationForms";
import { PlanWizardCtxProvider } from "~/views/plans/PlanCreate/components/PlanCreateWizard/PlanProvider";
import { TimeUnits } from "~/views/plans/PlanCreate/components/PlanCreateWizard/types";
import { ScheduleType } from "~/views/plans/types";
import { convertMinutesToReadableTime } from "~/views/plans/utils";
import { usePlan } from "../../PlanProvider";
import { GeneralNotificationTabBar } from "./GeneralNotificationTabBar";
import {
  getFormDataIds,
  getDeleteNotificationGroupsFromList,
  getDeleteNotificationGroupsFromNestedGroup,
  mapNotificationGroupsToUpdate,
  mapNotifications,
  mapNotificationGroups,
} from "./helpers";

export type State = {
  isOpen: boolean;
  hasUnsavedChanges: boolean;
  hasChanges: boolean;
};

export function GeneralNotificationsTab() {
  const [state, updateState] = useReducer(
    (data: State, partialData: Partial<State>) => {
      return {
        ...data,
        ...partialData,
      };
    },
    { isOpen: false, hasUnsavedChanges: false, hasChanges: false }
  );

  const { plan } = usePlan();
  const planNotifications = plan?.notificationGroups ?? [];

  const addListId = planNotifications.map((item) => {
    return {
      id: item.id,
      triggerEvent: item.triggerEvent,
      notificationGroupIds: item.notificationGroup
        ? [item.notificationGroup.id]
        : [],
      inAdvance: item.inAdvance?.toString() ?? "0",
      unit: TimeUnits.minutes,
    };
  });

  const [planData, setPlanData] = useState<PlanData>({
    details: undefined,
    schedule_plan: undefined,
    schedule_type: ScheduleType.recurring,
    plan_settings: undefined,
    plan_status: undefined,
    general_notifications: { notificationGroupsGeneral: addListId },
  });

  const success = () => {
    showSuccess(generateActionSuccessText("Plan")()("updated")());
    updateState({ isOpen: false });
  };

  const [updatePlan] = useUpdatePlan({
    onSuccess: success,
  });

  const onSubmit = (formData: FormData) => {
    const formDataIds = getFormDataIds(formData?.notificationGroupsGeneral);

    const deleteNotificationGroupsFromList =
      getDeleteNotificationGroupsFromList(
        plan?.notificationGroups ?? [],
        formDataIds ?? []
      );

    const deleteNotificationGroupsFromNestedGroup =
      getDeleteNotificationGroupsFromNestedGroup(
        formData.notificationGroupsGeneral,
        plan?.notificationGroups ?? []
      );

    const notificationGroupsToDelete = [
      ...deleteNotificationGroupsFromList,
      ...deleteNotificationGroupsFromNestedGroup,
    ];

    const notificationsGroupsToUpdate = mapNotificationGroupsToUpdate(
      formData?.notificationGroupsGeneral,
      plan?.notificationGroups
    );

    const notifications = mapNotifications(planNotifications);
    const notificationGroups = mapNotificationGroups(
      notificationsGroupsToUpdate
    );

    if (areObjectsEqual(notifications, notificationGroups)) {
      handleOnClose();
      return;
    }

    updatePlan({
      id: plan?.id ?? "",
      notificationGroups: notificationsGroupsToUpdate,
      notificationGroupsToDelete: notificationGroupsToDelete,
    });

    setPlanData((prevPlanData) => ({
      ...prevPlanData,
      [PlanField.GENERAL_NOTIFICATIONS]: {
        notificationGroupsGeneral: formData.notificationGroupsGeneral,
      },
    }));
  };

  const handleOnClose = () => {
    updateState({ hasChanges: true, isOpen: false });
  };

  return (
    <>
      <PlanWizardCtxProvider value={{ planData, setPlanData }}>
        <Box innerSpacing="spacing00">
          <GeneralNotificationTabBar
            openEditMode={() => updateState({ isOpen: true })}
            closeEditMode={handleOnClose}
            isEditMode={state.isOpen}
            hasUnsavedChanges={state.hasUnsavedChanges}
          />
          <Switch>
            <Case condition={state.isOpen}>
              <PlanGeneralNotificationForm
                notificationGroups={plan?.notificationGroups ?? []}
                updateState={updateState}
                state={state}
                onSubmit={onSubmit}
              />
            </Case>
            <Case condition={!state.isOpen && isNotEmpty(planNotifications)}>
              <Row data-testid="notification-table">
                <Col xs={8} sm={8} md={12} lg={12}>
                  {planNotifications?.map((item, index) => {
                    return (
                      <Box
                        boxStyle="lightGrey"
                        key={item.id}
                        mb={theme.spacing.spacing03}
                      >
                        <Text
                          size="sm"
                          tag="div"
                          color={theme.color.text.text02}
                        >
                          Notification #{index + 1}
                        </Text>
                        <Text tag="div" data-testid="notification-text">
                          <>
                            Send to{" "}
                            <Text tag="span" color={theme.color.text.info}>
                              {item.notificationGroup?.name}{" "}
                            </Text>
                            when{" "}
                            <Text tag="span" weight="medium">
                              {item.triggerEvent
                                ?.replace(/_/g, " ")
                                .toLowerCase()}
                            </Text>
                            <When
                              condition={
                                item.triggerEvent ===
                                PlanNotificationTriggerType.EventsIncoming
                              }
                            >
                              {" "}
                              in{" "}
                              <Text tag="span" weight="medium">
                                {convertMinutesToReadableTime(
                                  Number(item?.inAdvance)
                                )}
                              </Text>
                            </When>
                          </>
                        </Text>
                      </Box>
                    );
                  })}
                </Col>
              </Row>
            </Case>
            <Case condition={!state.isOpen && isEmpty(planNotifications)}>
              <NoData hasIcon message="There are no General Notifications" />
            </Case>
          </Switch>
        </Box>
      </PlanWizardCtxProvider>
    </>
  );
}
