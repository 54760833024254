/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import dayjs from "dayjs";
import { TextDescriptionCell } from "~/components/Tables/cells/TextDescriptionCell";
import { dateFormat } from "~/constants";
import { isNotEmpty } from "~/tools";
import { NextCronDatesTooltip } from "~/views/plans/PlansTable/NextCronDatesTooltip";
import { NextDateTooltip } from "../components/NextDateTooltip";
import { NextExecutionDatesTooltip } from "./NextExecutionDatesTooltip";

type Props = {
  timezone: string;
  cronExpression: string;
  nextExecutionDates: string[];
  scheduleExecutionDates: string[];
};

export function NextDateCell({
  timezone,
  cronExpression,
  nextExecutionDates,
  scheduleExecutionDates,
}: Props) {
  const timezoneDates = nextExecutionDates.map((date) => {
    return dayjs(date).tz(timezone).format(dateFormat.dayMonthYearHour);
  });

  const getNextExecutionText = () => {
    if (isNotEmpty(nextExecutionDates)) {
      return timezoneDates[0];
    } else if (timezone && isNotEmpty(scheduleExecutionDates)) {
      return "Please check details";
    }
    return "Time Not Defined";
  };

  return (
    <TextDescriptionCell
      text={getNextExecutionText() ?? ""}
      description=""
      tooltip={getTooltip(
        cronExpression,
        nextExecutionDates,
        scheduleExecutionDates
      )}
    />
  );
}

const getTooltip = (
  cronExpression: string,
  nextExecutionDates: string[],
  scheduleExecutionDates: string[]
) => {
  if (cronExpression) {
    return (
      <NextCronDatesTooltip
        {...{ cronExpression, nextExecutionDates: nextExecutionDates }}
      />
    );
  } else if (isNotEmpty(scheduleExecutionDates)) {
    return (
      <NextExecutionDatesTooltip nextExecutionDates={nextExecutionDates} />
    );
  }
  return <NextDateTooltip description="Plan will run on demand" />;
};
